import React, { Component } from "react";
import { Layout, Row, Col, Card } from "antd";
import ProductService from "../../services/productService";
import styled from "styled-components";

const { Header, Footer, Content } = Layout;
const { Meta } = Card;

const StyledLayout = styled(Layout)`
  background-color: #ffffff;
`;

const StyledHeader = styled(Header)`
  background-color: #eeeeee;
`;

const StyledContent = styled(Content)`
  margin: 50px;
  min-height: 60vh;
`;

const StyledFooter = styled(Footer)`
  background-color: #eeeeee;
  text-align: "center";
`;

const StyledCard = styled(Card)`
  margin: 10px;
  padding: 0;
  background-color: #eeeeee;
  text-transform: uppercase;
  font-family: sans-serif;
`;

class Home extends Component {
  state = { products: [] };

  componentDidMount() {
    this.getProducts();
  }

  getProducts = async () => {
    const products = await ProductService.getProducts();
    this.setState({ products });
  };

  render() {
    const { products } = this.state;
    return (
      <StyledLayout>
        <StyledHeader>
          <h1>100Essentials</h1>
        </StyledHeader>
        <StyledContent>
          <Row gutter={16}>
            {products.map(product => (
              <Col span={6} key={product._id}>
                <StyledCard
                  bordered={false}
                  bodyStyle={{ padding: 0 }}
                  cover={
                    <img
                      src={product.image1}
                      alt="product"
                      style={{ maxWidth: "100%" }}
                    />
                  }
                >
                  <Meta
                    title={product.title}
                    style={{ padding: 10, textAlign: "center" }}
                  />
                </StyledCard>
              </Col>
            ))}
          </Row>
        </StyledContent>
        <StyledFooter>Store ©2019 Created by Eranov23</StyledFooter>
      </StyledLayout>
    );
  }
}

export default Home;
