import axios from "axios";

const SERVER__URI =
  "https://1t38egjs0k.execute-api.us-east-1.amazonaws.com/dev/products";

const getProducts = async () => {
  const { data } = await axios.get(SERVER__URI);
  return data;
};

export default { getProducts };
